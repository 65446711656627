<template>
    
    <v-row v-if="executionCodes" dense>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="6" class="section-wrapper">
            
            <!--Блок Карточка исполнения-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Карточка_исполнения")}}</div>
                    
                    <!--если в решении по обращению указан флаг ходатайства о прекращении-->
                    <template v-if="document.Card.HasClosePetition">

                        <!--поле Поступило ходатайство о прекращении-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Поступило_ходатайство_о_прекращении") }}
                                        <span class="label-index-part">24</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ $t("Да") }}
                                </div>
                            </v-col>
                        </v-row>

                        <!--Поле Дата направления ответа либо перенаправления-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Дата_направления_ответа_либо_перенаправления") }}
                                        <span class="label-index-part">30</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ document.Card.SendDate ? $moment(document.Card.SendDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                    </template>
                    <template v-else>

                        <!--поле Решение по обращению-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Решение_по_обращению") }}
                                        <span class="label-index-part">27</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ document.Card.Decision ? document.Card.Decision.Value : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                        <!--поле Исходящий номер-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Исходящий_номер") }}
                                        <span class="label-index-part">31</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ document.Card.StOutNumber ? document.Card.StOutNumber : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                        <!--поле Суть ответа заявителю-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Суть_ответа_заявителю") }}
                                        <span class="label-index-part">28</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ document.Card.AnswerSummary ? document.Card.AnswerSummary : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                        <!--если в обращении указано какое-то решение-->
                        <template v-if="document.Card.Decision">

                            <!--если обращение направлено в уполномоченный гос. орган-->
                            <template v-if="isRedirect">
                                <!--Поле Направлено в уполномоченный гос. орган-->
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{ $t("Направлено_в_уполномоченный_гос._орган") }}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.SendedUGOText ? document.Card.SendedUGOText : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <!--Поле Дата направления ответа либо перенаправления-->
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label label-with-index">
                                            <div>
                                                {{$t("Дата_направления_ответа_либо_перенаправления")}}
                                                <span class="label-index-part">30</span>
                                            </div>
                                        </label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.SendDate ? $moment(document.Card.SendDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>

                            <!--если по обращению направлен запрос в другой орган-->
                            <template v-else-if="isSendedTo">
                                <!--Поле Направлен запрос в другой орган-->
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{ $t("Направлен_запрос_в_другой_орган") }}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.SendRequestText ? document.Card.SendRequestText : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <!--Поле Дата направления запроса-->
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label label-with-index">{{ $t("Дата_направления_запроса") }}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.SendRequestDate ? $moment(document.Card.SendRequestDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-else>
                                <!--Поле Дата направления ответа либо перенаправления-->
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label label-with-index">
                                            <div>
                                                {{$t("Дата_направления_ответа_либо_перенаправления")}}
                                                <span class="label-index-part">30</span>
                                            </div>
                                        </label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.SendDate ? $moment(document.Card.SendDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>

                        </template>

                    </template>

                </v-card-text>
            </v-card>

        </v-col>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="6" class="section-wrapper">
            
            <!--Блок Привлечено к ответственности-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Привлечено_к_ответственности")}}</div>

                    <!--поле Количество лиц привлеченных к дисциплинарной ответственности-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Количество_лиц_привлеченных_к_дисциплинарной_ответственности") }}
                                    <span class="label-index-part">32</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.DiscCount }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--поле Количество лиц привлеченных к административной ответственности-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Количество_лиц_привлеченных_к_административной_ответственности") }}
                                    <span class="label-index-part">32</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.AdmCount }}
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

        </v-col>

    </v-row>
    
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "EuolExecution",
    computed: {
        ...mapGetters('global/actionsource', { dataSource: 'getDataSource' }),
        document() {
            return this.dataSource.Data.Object.Document;
        },
        isRedirect() {
            return this.redirectCodes.includes(this.document.Card.Decision.Code);
        },
        isSendedTo() {
            return this.sendedToCodes.includes(this.document.Card.Decision.Code);
        }
    },
    data() {
        return {
            executionCodes: null,
            redirectCodes: [ "6", "7", "8", "9" ],
            sendedToCodes: [ "10" ]
        }
    },
    methods: {
        ...mapActions('documents/references', ['getReference'])
    },
    async created() {
        this.executionCodes = await this.getReference({ id: 1034 });
    }
}
</script>