<template>
    
    <v-row dense>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="6" class="section-wrapper">
            
            <!--Блок Сведения о регистрации-->
            <v-card flat v-if="!document.is_new_record && isRegistered">
                <v-card-text>
                    <div class="form-box-title">{{ $t("Сведения_о_регистрации") }}</div>

                    <!--Поле Орган зарегистрировавший обращение-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Орган_зарегистрировавший_обращение") }}
                                    <span class="label-index-part">1</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.RegOrganText }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Рег. номер-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Рег._номер") }}
                                    <span class="label-index-part">9</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.RegNumber }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Дата регистрации-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Дата_регистрации") }}
                                    <span class="label-index-part">9</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.RegDate ? $moment(document.Card.RegDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Уникальный номер-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Уникальный_номер") }}
                                    <span class="label-index-part">8</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ !!document.Card.UniqueNumber && document.Card.UniqueNumber.length > 0 ? document.Card.UniqueNumber : $t("Не_указано")  }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Регистратор-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Регистратор")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                <v-workplace-chip :id="document.Card.RegistratorWorkplaceId" :name="document.Card.RegistratorName" />
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

            <!--Блок Общие сведения-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Общие_сведения")}}</div>
                    
                    <!--поле Тип носителя-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Тип_носителя")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <div
                                v-if="isViewMode || isPortalWithValue(document.Card.CarrierType)"
                                class="onlyReadData"
                            >
                                {{document.Card.CarrierType ? document.Card.CarrierType.Value : $t("Не_указано")}}
                            </div>
                            <v-autocomplete
                                v-else
                                :value="document.Card.CarrierType"
                                @input="update({ property: 'Data.Object.Document.Card.CarrierType', value: $event })"
                                :items="GetReference(1024)"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                return-object
                                hide-details
                                required
                                outlined
                                dense
                                :rules="requiredRule"
                                append-icon="fas fa-chevron-down"
                            >
                            </v-autocomplete>

                        </v-col>
                    </v-row>

                    <!--поле Вид обращения-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Вид_обращения")}}
                                    <span class="label-index-part">14</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <div
                                v-if="isViewMode || isPortalWithValue(document.Card.StType)"
                                class="onlyReadData"
                            >
                                {{document.Card.StType ? document.Card.StType.Value : $t("Не_указано")}}
                            </div>
                            <v-autocomplete
                                v-else
                                :value="document.Card.StType"
                                @input="update({ property: 'Data.Object.Document.Card.StType', value: $event })"
                                :items="GetReference(5205)"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                return-object
                                hide-details
                                required
                                outlined
                                dense
                                :rules="requiredRule"
                                append-icon="fas fa-chevron-down"
                            >
                            </v-autocomplete>

                        </v-col>
                    </v-row>

                    <!--поле Критерий классификации-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Критерий_классификации") }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <div
                                v-if="isViewMode || isPortalWithValue(document.Card.Classification)"
                                class="onlyReadData"
                            >
                                {{document.Card.Classification ? document.Card.Classification.Value : $t("Не_указано")}}
                            </div>
                            <v-autocomplete
                                v-else
                                :value="document.Card.Classification"
                                @input="update({ property: 'Data.Object.Document.Card.Classification', value: $event })"
                                :items="GetReference(5216)"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                return-object
                                hide-details
                                required
                                outlined
                                dense
                                append-icon="fas fa-chevron-down"
                            >
                            </v-autocomplete>

                        </v-col>
                    </v-row>

                    <!--поле Характер вопроса обращения-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Характер_вопроса_обращения") }}
                                    <span class="label-index-part">22</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <div
                                v-if="isViewMode || isPortalWithValue(document.Card.StQuestion)"
                                class="onlyReadData"
                            >
                                {{document.Card.StQuestion ? documentStQuestions.map((x) => x.Value).join(', ') : $t("Не_указано")}}
                            </div>
                            <v-select
                                v-else
                                v-model="documentStQuestions"
                                multiple
                                :items="characterQuestionItems"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                return-object
                                hide-details
                                required
                                outlined
                                dense
                                :rules="multipleRule"
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-text-field 
                                                :placeholder="$t('Поиск')"
                                                class="mb-2 autocomplete-search"
                                                hide-details
                                                dense
                                                clearable
                                                @input="search"
                                                autofocus
                                            >
                                            </v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>

                        </v-col>
                    </v-row>

                    <!--поле Форма обращения-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Форма_обращения") }}
                                    <span class="label-index-part">11</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div
                                v-if="isViewMode || isPortalWithValue(document.Card.StForm)"
                                class="onlyReadData"
                            >
                                {{document.Card.StForm ? document.Card.StForm.Value : $t("Не_указано")}}
                            </div>
                            <v-autocomplete
                                v-else
                                :value="document.Card.StForm"
                                @input="update({ property: 'Data.Object.Document.Card.StForm', value: $event })"
                                :items="GetReference(5204)"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                return-object
                                hide-details
                                required
                                outlined
                                dense
                                :rules="requiredRule"
                                append-icon="fas fa-chevron-down"
                            >
                            </v-autocomplete>

                        </v-col>
                    </v-row>

                    <!--поле Язык обращения-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Язык_обращения") }}
                                    <span class="label-index-part">15</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div
                                v-if="isViewMode || isPortalWithValue(document.Card.StLanguage)"
                                class="onlyReadData"
                            >
                                {{document.Card.StLanguage ? document.Card.StLanguage.Value : $t("Не_указано")}}
                            </div>
                            <v-autocomplete
                                v-else
                                :value="document.Card.StLanguage"
                                @input="update({ property: 'Data.Object.Document.Card.StLanguage', value: $event })"
                                :items="GetReference(5206)"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                return-object
                                hide-details
                                required
                                outlined
                                dense
                                :rules="requiredRule"
                                append-icon="fas fa-chevron-down"
                            >
                            </v-autocomplete>

                        </v-col>
                    </v-row>
                    
                    <!--поле Количество листов/приложений-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Количество_листов/приложений") }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <div v-if="isViewMode" class="onlyReadData">
                                {{`${document.Card.ListCount}/${document.Card.AttachCount}`}}
                            </div>
                            <div class="more-inputs-in-row" v-else>
                                <v-text-field
                                    :value="document.Card.ListCount"
                                    @input="update({ property: 'Data.Object.Document.Card.ListCount', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    type="number"
                                    :rules="integerRule.concat(requiredRule)"
                                    class="cust-inputnumber"
                                    min="0"
                                >
                                </v-text-field>
                                <span>/</span>
                                <v-text-field
                                    :value="document.Card.AttachCount"
                                    @input="update({ property: 'Data.Object.Document.Card.AttachCount', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    type="number"
                                    :rules="integerRule.concat(requiredRule)"
                                    class="cust-inputnumber"
                                    min="0"
                                >
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>

                    <!--поле Отправлять в ШЭП-->
                    <v-row no-gutters v-if="false">
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Отправлять_в_ШЭП")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{ document.Card.IsForAikyn ? $t("Да") : $t("Нет") }}
                            </div>
                            <v-switch
                                v-else
                                :input-value="document.Card.IsForAikyn"
                                @change="update({ property: 'Data.Object.Document.Card.IsForAikyn', value: $event })"
                                inset
                                hide-details
                                class="cust-switch"
                            />
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

            <!--Блок Суть обращения-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Суть_обращения")}}</div>
                    
                    <!--Поле Причина повторного-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Причина_повторного")}}
                                    <span class="label-index-part">12</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.DuplicateReason ? document.Card.DuplicateReason.Value : $t("Не_указано") }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Признак повторного или дубликата-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Признак_повторного_или_дубликата")}}
                                    <span class="label-index-part">13</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.Duplicate }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Наименование органа, действия которого обжалуются-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Наименование_органа_действия_которого_обжалуются")}}
                                    <span class="label-index-part">20</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{ document.Card.AppealEnterpriseText ? document.Card.AppealEnterpriseText : $t("Не_указано") }}
                            </div>
                            <v-text-field
                                v-else
                                class="prepend-icon-tf"
                                :readonly="appealEnterpriseTextReadonly"
                                :value="document.Card.AppealEnterpriseText"
                                hide-details
                                required
                                outlined
                                dense
                                @input="onAppealEnterpriseTextInput"
                                clearable
                                @click:clear="onAppealEnterpriseTextClear"
                                :append-icon="appealEnterpriseTextReadonly ? 'mdi-dots-horizontal' : ''"
                                @click:append="onAppealEnterpriseTextSelect"
                                :rules="document.Card.StType && document.Card.StType.Code === '078' ? requiredRule : []"
                            >
                                <template v-slot:prepend>
                                    <div class="v-input__icon v-input__icon--prepend">
                                        <v-icon
                                            @click="onAppealEnterpriseTextReadonlyToggle"  
                                            v-tooltip.top-center="appealEnterpriseTextReadonly ? $t('Выбрать_из_списка') : $t('Ввести_вручную')"  
                                        >
                                            {{ appealEnterpriseTextReadonly ? 'mdi-pencil-plus' : 'mdi-menu' }}
                                        </v-icon>
                                    </div>
                                </template>
                            </v-text-field>

                        </v-col>
                    </v-row>

                    <!--Поле ФИО должностного лица действия которого обжалуются-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("ФИО_должностного_лица_действия_которого_обжалуются")}}
                                    <span class="label-index-part">21</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{ document.Card.AppealEmployee ? document.Card.AppealEmployee : $t("Не_указано") }}
                            </div>
                            <v-text-field
                                v-else
                                :value="document.Card.AppealEmployee"
                                @input="update({ property: 'Data.Object.Document.Card.AppealEmployee', value: $event })"
                                hide-details
                                required
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

        </v-col>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="6" class="section-wrapper">
            
            <!--Виджет исполнения-->
            <v-card 
                v-if="!document.is_new_record && isRegistered"
                class="execution-form-widget" 
                flat
            >
                <v-card-text :class="executionWidgetBackground">

                    <div class="efw-title">
                        {{ $t("Срок_исполнения") }}:
                        <template v-if="document.Card.ProlongationDate">{{ $moment(document.Card.ProlongationDate).format("DD.MM.YYYY") }}</template>
                        <template v-else>{{ $moment(document.Card.ControlDate).format("DD.MM.YYYY") }}</template>
                    </div>

                    <div class="efw-desc-wrap">
                        <div class="efw-description">{{ $t("Ответственный_исполнитель") }}:</div>

                        <v-workplace-chip
                            v-if="document.Card.InnerExecuterId === '00000000-0000-0000-0000-000000000000'"
                            :id="document.Card.ExecuterWorkplaceId"
                            :name="document.Card.ExecuterName"
                            :main="true"
                        />
                        <v-workplace-chip
                            v-else
                            :id="document.Card.InnerExecuterId"
                            :name="document.Card.InnerExecuterName"
                            :main="true"
                        />

                    </div>

                </v-card-text>

            </v-card>

            <!--Блок Заявитель-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Заявитель")}}</div>
                    
                    <!--Поле Категория лица-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Категория_лица") }}</label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ $t(declarantCategory) }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Если заявитель Юридическое лицо добавляем поля Наименование юр. лица и Исходящий номер, дата юр. лица-->
                    <template v-if="document.Card.PerCategory === 2">

                        <!--Поле Наименование юр. лица-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Наименование_юр._лица") }}
                                        <span class="label-index-part">5</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div v-if="isViewMode" class="onlyReadData">
                                    {{ document.Card.PerEnterpriseName ? document.Card.PerEnterpriseName : $t("Не_указано") }}
                                </div>
                                <v-text-field
                                    v-else
                                    :value="document.Card.PerEnterpriseName"
                                    @input="update({ property: 'Data.Object.Document.Card.PerEnterpriseName', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    :rules="requiredRule"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <!--поле Исходящий номер, дата юр. лица-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Исходящий_номер,_дата_юр._лица") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div v-if="isViewMode" class="onlyReadData">
                                    {{ concatedJuridicalRegistrationData  }}
                                </div>
                                <div v-else class="more-inputs-in-row">

                                    <v-text-field
                                        :value="document.Card.JuridicalRegNumber"
                                        @input="update({ property: 'Data.Object.Document.Card.JuridicalRegNumber', value: $event })"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="requiredRule"
                                    >
                                    </v-text-field>

                                    <span>{{ $t("от_время_редактирование") }}</span>

                                    <v-menu 
                                        v-model="juridicalDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="juridicalRegDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                class="datepick-input"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="juridicalRegDate"
                                            @input="juridicalDateMenu = false"
                                            color="teal"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                    
                                </div>
                            </v-col>
                        </v-row>

                    </template>

                    <!--Поле ИИН/БИН-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ document.Card.PerCategory === 2 ? $t("БинU") : $t("ИинU") }}
                                    <span class="label-index-part">6</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{ document.Card.PerIIN_BIN ? document.Card.PerIIN_BIN : $t("Не_указано") }}
                            </div>
                            <v-text-field
                                v-else
                                :value="document.Card.PerIIN_BIN"
                                @input="update({ property: 'Data.Object.Document.Card.PerIIN_BIN', value: $event })"
                                hide-details
                                required
                                outlined
                                dense
                                :rules="document.Card.StType && document.Card.StType.Code === '095' ? [...requiredRule, ...iinRule] : iinRule"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!--ФИО, если карточка в режиме просмотра-->
                    <template v-if="isViewMode">

                        <!--Поле Орган зарегистрировавший обращение-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Фио") }}
                                        <span class="label-index-part">2-3</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ concatedName }}
                                </div>
                            </v-col>
                        </v-row>

                    </template>
                    <!--ФИО, если карточка в режиме редактирования-->
                    <template v-else>

                        <!--Поле Фамилия-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Фамилия") }}
                                        <span class="label-index-part">2</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    :value="document.Card.PerFirstName"
                                    @input="update({ property: 'Data.Object.Document.Card.PerFirstName', value: $event })"
                                    hide-details
                                    outlined
                                    dense
                                    :rules="hasAnonimSelectedSocialStatuses ? [] : requiredRule"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <!--Поле Имя-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Имя") }}
                                        <span class="label-index-part">3</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    :value="document.Card.PerSecondName"
                                    @input="update({ property: 'Data.Object.Document.Card.PerSecondName', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    :rules="hasAnonimSelectedSocialStatuses ? [] : requiredRule"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <!--Поле Отчество-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Отчество") }}
                                        <span class="label-index-part">3</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    :value="document.Card.PerMiddleName"
                                    @input="update({ property: 'Data.Object.Document.Card.PerMiddleName', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                    </template>

                    <!--Поле Почтовый адрес-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Почтовый_адрес") }}
                                    <span class="label-index-part">4</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{ document.Card.PerAddress ? document.Card.PerAddress : $t("Не_указано") }}
                            </div>
                            <v-text-field
                                v-else
                                :value="document.Card.PerAddress"
                                @input="update({ property: 'Data.Object.Document.Card.PerAddress', value: $event })"
                                hide-details
                                required
                                outlined
                                dense
                                :rules="hasAnonimSelectedSocialStatuses ? [] : requiredRule"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!--Поле Телефон-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Телефон") }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{ document.Card.PerPhone ? document.Card.PerPhone : $t("Не_указано") }}
                            </div>
                            <v-text-field
                                v-else
                                :value="document.Card.PerPhone"
                                @input="update({ property: 'Data.Object.Document.Card.PerPhone', value: $event })"
                                hide-details
                                required
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!--Поле Электронная почта-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{ $t("Электронная_почта") }}
                                    <span class="label-index-part">4-1</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{ document.Card.PerEmail ? document.Card.PerEmail : $t("Не_указано") }}
                            </div>
                            <v-text-field
                                v-else
                                :value="document.Card.PerEmail"
                                @input="update({ property: 'Data.Object.Document.Card.PerEmail', value: $event })"
                                hide-details
                                required
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!--Если заявитель Физическое лицо добавляем поля Социальный статус и Гражданство-->
                    <template v-if="document.Card.PerCategory === 1">

                        <!--поле Социальный статус-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label label-with-index">
                                    <div>
                                        {{ $t("Социальный_статус") }}
                                        <span class="label-index-part">7</span>
                                    </div>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <div
                                    v-if="isViewMode"
                                    class="onlyReadData"
                                >
                                    {{document.Card.PerSocialStatus ? socialStatuses.map((x) => x.Value).join(', ') : $t("Не_указано")}}
                                </div>
                                <v-select
                                    v-else
                                    v-model="socialStatuses"
                                    multiple
                                    :items="socialStatusesItems"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    return-object
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    :rules="multipleRule"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    @input="searchSocialStatus"
                                                    autofocus
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>

                        <!--поле Гражданство-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t("Гражданство")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <div
                                    v-if="isViewMode"
                                    class="onlyReadData"
                                >
                                    {{document.Card.PerCitizenship ? document.Card.PerCitizenship.Value : $t("Не_указано")}}
                                </div>
                                <v-autocomplete
                                    v-else
                                    :value="document.Card.PerCitizenship"
                                    @input="update({ property: 'Data.Object.Document.Card.PerCitizenship', value: $event })"
                                    :items="GetReference(5203)"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    return-object
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                >
                                </v-autocomplete>

                            </v-col>
                        </v-row>

                    </template>

                </v-card-text>
            </v-card>

            <!--Блок Реквизиты отправителя-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Реквизиты_отправителя")}}</div>
                    
                    <!--поле Портальное обращение (только для портальных обращений, всегда просмотр)-->
                    <v-row v-if="document.IsPortal" no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Портальное_обращение")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.IsPortal ? $t("Да") : $t("Нет") }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Примечание ПЭП (только для портальных обращений, всегда просмотр)-->
                    <v-row v-if="document.IsPortal" no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Примечание_ПЭП_medium") }}</label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.Note ? document.Card.Note : $t("Не_указано")  }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Обращение поступило из (для портальных обращений редактирование недоступно-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Обращение_поступило_из")}}
                                    <span class="label-index-part">16</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode || document.IsPortal" class="onlyReadData">
                                {{ document.Card.ComeFromText ? document.Card.ComeFromText : $t("Не_указано")  }}
                            </div>
                            <v-text-field
                                v-else
                                class="prepend-icon-tf"
                                :readonly="comeFromTextReadonly"
                                :value="document.Card.ComeFromText"
                                hide-details
                                required
                                outlined
                                dense
                                @input="onComeFromTextInput"
                                clearable
                                @click:clear="onComeFromTextClear"
                                :append-icon="comeFromTextReadonly ? 'mdi-dots-horizontal' : ''"
                                @click:append="onComeFromTextSelect"
                            >
                                <template v-slot:prepend>
                                    <div class="v-input__icon v-input__icon--prepend">
                                        <v-icon
                                            @click="onComeFromTextReadonlyToggle"  
                                            v-tooltip.top-center="comeFromTextReadonly ? $t('Выбрать_из_списка') : $t('Ввести_вручную')"  
                                        >
                                            {{ comeFromTextReadonly ? 'mdi-pencil-plus' : 'mdi-menu' }}
                                        </v-icon>
                                    </div>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!--Поле Поступило из вышестоящего органа (для портальных обращений редактирование недоступно-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Поступило_из_вышестоящего_органа")}}
                                    <span class="label-index-part">17</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode || document.IsPortal" class="onlyReadData">
                                {{ document.Card.IsFromHigher ? $t("Да") : $t("Нет") }}
                            </div>
                            <v-switch
                                v-else
                                :input-value="document.Card.IsFromHigher"
                                @change="update({ property: 'Data.Object.Document.Card.IsFromHigher', value: $event })"
                                inset
                                hide-details
                                class="cust-switch"
                            />
                        </v-col>
                    </v-row>

                    <!--Поле Исходящий номер и дата отправления в субъект (для портальных обращений редактирование недоступно-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Исходящий_номер_и_дата_отправления_в_субъект")}}
                                    <span class="label-index-part">10</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode || document.IsPortal" class="onlyReadData">
                                {{ concatedOutRegistrationData }}
                            </div>
                            <div v-else class="more-inputs-in-row">

                                <v-text-field
                                    :value="document.Card.OutNumber"
                                    @change="update({ property: 'Data.Object.Document.Card.OutNumber', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-text-field>

                                <span>{{ $t("от_время_редактирование") }}</span>

                                <v-menu 
                                    v-model="outDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="outDate | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker
                                        v-model="outDate"
                                        @input="outDateMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

        </v-col>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="12" class="section-wrapper">

            <!--Блок Краткое содержание-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Краткое_содержание")}}</div>

                    <!--Поле Краткое содержание-->
                    <v-row class="full-width-row" no-gutters>
                        <v-col cols="12" sm="12" md="2">
                            <label class="f-label">{{$t("Краткое_содержание")}}</label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="10">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{document.Card.Annotation}}
                            </div>
                            <v-textarea
                                v-else
                                :value="document.Card.Annotation"
                                @input="update({ property: 'Data.Object.Document.Card.Annotation', value: $event })"
                                rows="3"
                                no-resize
                                hide-details
                                required
                                outlined
                                dense
                                :rules="requiredRule"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

            <!--Блок На рассмотрение-->
            <v-card v-if="isViewMode" flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("На_рассмотрение")}}</div>
                    
                    <v-row class="full-width-row" no-gutters>
                        <v-col cols="12" sm="12" md="2">
                            <label class="f-label">{{$t("Получатели")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="10">

                            <div v-if="document.Card.Recipients && document.Card.Recipients.length > 0" class="onlyReadData more-per-lab-wrap">

                                <template v-for="recipient in document.Card.Recipients">
                                    <v-workplace-chip
                                        :key="recipient.Id"
                                        :id="recipient.Id"
                                        :name="recipient.Name"
                                        :is-employee="recipient.IsEmployee"
                                    />
                                </template>

                            </div>

                            <div v-else class="onlyReadData">
                                {{ $t("Не_указано") }}
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

        </v-col>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="6" class="section-wrapper">
            
            <!--Блок Кем создано-->
            <v-card flat v-if="isViewMode">
                <v-card-text>
                    <div class="form-box-title">{{ $t("Кем_создано") }}</div>
                    
                    <!--Поле Сведения внес в АИС субъекта-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Сведения_внес_в_АИС_субъекта")}}</label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                <v-employee-chip v-if="document.AuthorName" :id="document.AuthorId" :name="document.AuthorName" />
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Дата создания-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Дата_создания") }}</label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.CreateDate ? $moment(document.CreateDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

        </v-col>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="6" class="section-wrapper">
            
            <!--Блок Дополнительные сведения-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Дополнительные_сведения")}}</div>
                    
                    <!--поле Район-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Район")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{ document.Card.Region ? document.Card.Region.Value : $t("Не_указано") }}
                            </div>
                            <v-select
                                v-else
                                :value="document.Card.Region"
                                @change="update({ property: 'Data.Object.Document.Card.Region', value: $event })"
                                :items="GetReference(5215)"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                return-object
                                hide-details
                                required
                                outlined
                                dense
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-text-field 
                                                :placeholder="$t('Поиск')"
                                                class="mb-2 autocomplete-search"
                                                hide-details
                                                dense
                                                clearable
                                                autofocus
                                            >
                                            </v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <!--поле Сфера-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Сфера")}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div v-if="isViewMode" class="onlyReadData">
                                {{document.Card.Scope ? scopes.map((x) => x.Value).join(', ') : $t("Не_указано")}}
                            </div>
                            <v-autocomplete
                                v-else
                                v-model="scopes"
                                multiple
                                :items="GetReference(1036)"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                return-object
                                hide-details
                                required
                                outlined
                                dense
                                append-icon="fas fa-chevron-down"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
            
        </v-col>
    </v-row>

   
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';
import _ from 'lodash'

export default {
    name: "EuolStatementCard",
    computed: {
        ...mapGetters('documents/references', ['GetReference', 'GetDeclarantCategoryTypes']),
        ...mapGetters('global/actionsource', { dataSource: 'getDataSource' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            multipleRule: 'getMultipleRule',
            integerRule: 'getIntegerRule',
            iinRule: 'getIinRule'
        }),
        document() {
            return this.dataSource.Data.Object.Document;
        },
        isViewMode() {
            return this.dataSource.Data.FormId === "0901021";
        },
        isRegistered() {
            return this.document.RegState == 1 || this.document.RegState == 4;
        },
        documentStQuestions: {
            get: function() {
                return this.document.Card.StQuestion?.data_list ?? [];
            },
            set: function(value) {
                if (this.document.Card.StQuestion != null)
                    this.updateSource({ property: 'Data.Object.Document.Card.StQuestion.data_list', value });
                else
                    this.updateSource({ property: 'Data.Object.Document.Card.StQuestion', value: { data_list: value } });
            }
        },
        socialStatuses: {
            get: function() {
                return this.document.Card.PerSocialStatus?.data_list ?? [];
            },
            set: function(value) {
                if (this.document.Card.PerSocialStatus != null)
                    this.updateSource({ property: 'Data.Object.Document.Card.PerSocialStatus.data_list', value });
                else
                    this.updateSource({ property: 'Data.Object.Document.Card.PerSocialStatus', value: { data_list: value } });
            }
        },
        scopes: {
            get: function() {
                return this.document.Card.Scope?.data_list ?? [];
            },
            set: function(value) {
                if (this.document.Card.Scope != null)
                    this.updateSource({ property: 'Data.Object.Document.Card.Scope.data_list', value });
                else
                    this.updateSource({ property: 'Data.Object.Document.Card.Scope', value: { data_list: value } });
            }
        },
        declarantCategory() {
            return this.GetDeclarantCategoryTypes.find(category => category.id === this.document.Card.PerCategory).Value;
        },
        concatedJuridicalRegistrationData() {
            let result = this.$t("Не_указано");

            if (this.document.Card.JuridicalRegNumber?.length)
                result = `№ ${this.document.Card.JuridicalRegNumber}`;

            if (this.document.Card.JuridicalRegDate)
                result += ` ${this.$t("от_время")} ${this.$moment(this.document.Card.JuridicalRegDate).format("DD.MM.YYYY")} г.`;

            return result;
        },
        concatedName() {
            let result = this.$t("Не_указано");

            if (this.document.Card.PerFirstName)
                result = this.document.Card.PerFirstName;

            if (this.document.Card.PerSecondName)
                result += ` ${this.document.Card.PerSecondName}`;

            if (this.document.Card.PerMiddleName)
                result += ` ${this.document.Card.PerMiddleName}`;

            return result;
        },
        concatedOutRegistrationData() {
            let result = this.$t("Не_указано");

            if (this.document.Card.OutNumber?.length)
                result = `№ ${this.document.Card.OutNumber}`;

            if (this.document.Card.OutDate)
                result += `${this.$t("от_время")} ${this.$moment(this.document.Card.OutDate).format("DD.MM.YYYY")} г.`;

            return result;
        },
        executionWidgetBackground() {
            let result = 'mdwi-gr-grey';
            
            if (this.document.Card.IsExecuted)
                result = 'mdwi-gr-green';
            else if (this.$moment().diff(this.$moment(this.document.Card.ProlongationDate ? this.document.Card.ProlongationDate : this.document.Card.ControlDate), 'days') > 1)
                result = 'mdwi-gr-aqua';

            return result;
        },
        //#region Даты
        juridicalRegDate: {
            get: function() {
                if (this.document.Card.JuridicalRegDate)
                    return this.$moment(this.document.Card.JuridicalRegDate).format('YYYY-MM-DD');
                
                return this.document.Card.JuridicalRegDate;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.JuridicalRegDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        outDate: {
            get: function() {
                if (this.document.Card.OutDate)
                    return this.$moment(this.document.Card.OutDate).format('YYYY-MM-DD');
                
                return this.document.Card.OutDate;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.OutDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        //#endregion
        hasAnonimSelectedSocialStatuses() {
            return this.socialStatuses.some(
                ({ id }) => id === 10
            );
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data(){
        return {
            //для выбора даты исходящего в режиме редактирования
            juridicalDateMenu: false,
            //для выбора даты отправления в субъект в режиме редактирования
            outDateMenu: false,
            //для выбора режима вводы 
            comeFromTextReadonly: true,
            appealEnterpriseTextReadonly: true,

            characterQuestionItems: [],
            socialStatusesItems: [],
        }
    },
    methods: {
        async initialize(){
            this.characterQuestionItems = await this.getReference({ id: 1003 });
            this.socialStatusesItems = await this.getReference({ id: 1040 })
        },
        ...mapActions('global/actionsource', { updateSource: 'updateDataSource' }),
        ...mapActions('documents/references', ['getReference']),
        isPortalWithValue(source) {
            return this.IsPortal && !!source;
        },
        onComeFromTextClear() {
            this.updateSource({ property: 'Data.Object.Document.Card.ComeFromText', value: null });
            this.updateSource({ property: 'Data.Object.Document.Card.ComeFromGuid', value: '00000000-0000-0000-0000-000000000000' });
        },
        onComeFromTextReadonlyToggle() {
            this.updateSource({ property: 'Data.Object.Document.Card.ComeFromText', value: null });
            this.updateSource({ property: 'Data.Object.Document.Card.ComeFromGuid', value: '00000000-0000-0000-0000-000000000000' });
            this.comeFromTextReadonly = !this.comeFromTextReadonly;
        },
        onComeFromTextInput(value) {
            this.updateSource({ property: 'Data.Object.Document.Card.ComeFromText', value });
            this.updateSource({ property: 'Data.Object.Document.Card.ComeFromGuid', value: '00000000-0000-0000-0000-000000000000' });
        },
        async onComeFromTextSelect() {
            let selectMemberParams =
            { 
                title: "Выбор_организации",
                multiple: false,
                includeOuterEnterprises: true,
                selected: [ this.document.Card.ComeFromGuid ],
                disableEmptySelect: true
            }

            try
            {
                let { enterprise, name } = await this.$store.dispatch('global/dialogs/selectMembersNew/open', selectMemberParams);

                this.updateSource({ property: 'Data.Object.Document.Card.ComeFromText', value: name });
                this.updateSource({ property: 'Data.Object.Document.Card.ComeFromGuid', value: enterprise });
            }
            catch(ex)
            {
                console.log(ex.message);
            }
        },
        onAppealEnterpriseTextClear() {
            this.updateSource({ property: 'Data.Object.Document.Card.AppealEnterpriseText', value: null });
            this.updateSource({ property: 'Data.Object.Document.Card.AppealEnterpriseGuid', value: '00000000-0000-0000-0000-000000000000' });
        },
        onAppealEnterpriseTextReadonlyToggle() {
            this.updateSource({ property: 'Data.Object.Document.Card.AppealEnterpriseText', value: null });
            this.updateSource({ property: 'Data.Object.Document.Card.AppealEnterpriseGuid', value: '00000000-0000-0000-0000-000000000000' });
            this.appealEnterpriseTextReadonly = !this.appealEnterpriseTextReadonly;
        },
        onAppealEnterpriseTextInput(value) {
            this.updateSource({ property: 'Data.Object.Document.Card.AppealEnterpriseText', value });
            this.updateSource({ property: 'Data.Object.Document.Card.AppealEnterpriseGuid', value: '00000000-0000-0000-0000-000000000000' });
        },
        async onAppealEnterpriseTextSelect() {
            let selectMemberParams =
            { 
                title: "Выбор_организации",
                multiple: false,
                includeOuterEnterprises: true,
                selected: [ this.document.Card.AppealEnterpriseGuid ],
                disableEmptySelect: true
            }

            try
            {
                let { enterprise, name } = await this.$store.dispatch('global/dialogs/selectMembersNew/open', selectMemberParams);
                this.updateSource({ property: 'Data.Object.Document.Card.AppealEnterpriseText', value: name });
                this.updateSource({ property: 'Data.Object.Document.Card.AppealEnterpriseGuid', value: enterprise });
            }
            catch(ex)
            {
                console.log(ex.message);
            }
        },
        update: _.debounce(function (object) {            
            this.updateSource(object);
        }, 250),
        async search(e) {
            if (e) {
                let selectedIds = this.documentStQuestions.length ? this.documentStQuestions.map(i => i.id): [];                    
                this.characterQuestionItems = (await this.getReference({ id: 1003 })).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.characterQuestionItems = await this.getReference({ id: 1003 });
        },
        async searchSocialStatus(e) {
            if (e) {
                let selectedIds = this.socialStatuses.length ? this.socialStatuses.map(i => i.id): [];                    
                this.socialStatusesItems = (await this.getReference({ id: 1040 })).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.socialStatusesItems = await this.getReference({ id: 1040 });
        }
    },
    async created() {
        await this.initialize();
    }
}
</script>