<template>

    <div v-if="dataSource && dataSourceType === 'EuolStatement'"
        :key="document.id"
        class="p-relative"
    >

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <DocumentPanel :document-info="this.dataSource.Data.Object" />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="document.id + item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{ item.Count }}</span>
                </template>
                
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-form class="wrapperForm" ref="form" lazy-validation>
                <v-tab-item 
                    v-for="item in pages"
                    :key="document.id + item.FormId"
                >
                    <template v-if="item.FormId == document.id">
                        <EuolCard />
                    </template>

                    <template v-else-if="item.FormId == 'EuolControl'">
                        <EuolControl />
                    </template>

                    <template v-else-if="item.FormId == 'EuolExecution'">
                        <EuolExecution />
                    </template>

                    <template v-else-if="item.FormId == 'EuolSimilar'">
                        <EuolSimilar />
                    </template>

                    <template v-else>
                        <component
                            :is="$mapComponent(item.FormId)" 
                            :ref="item.FormId" 
                            :id="document.id"
                        />
                    </template>

                </v-tab-item>  
            </v-form>              
        </v-tabs-items>

    </div>

</template>

<script>
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import { mapGetters, mapActions } from 'vuex';

import Toolbar from '@/components/documents/Toolbar.vue';
import DocumentPanel from '@/components/documents/DocumentPanel.vue';

import EuolCard from '@/components/documents/tabs/euol-statement/Card';
import EuolControl from '@/components/documents/tabs/euol-statement/Control';
import EuolExecution from '@/components/documents/tabs/euol-statement/Execution';
import EuolSimilar from '@/components/documents/tabs/euol-statement/Similar';

export default {
    name: "EuolStatement",
    components: {
        Toolbar,
        DocumentPanel,
        EuolCard,
        EuolControl,
        EuolExecution,
        EuolSimilar
    },
    computed: {
        ...mapGetters('global/actionsource', { dataSource: 'getDataSource', dataSourceType: 'getDataSourceType' }),
        document() {
            return this.dataSource?.Data.Object.Document;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.document?.id, Name: this.$t("Карточка") },
                { Count: -1, FormId: "EuolControl", Name: this.$t("Контроль") }
            ];

            if (this.document?.Card.IsExecuted)
                pages.push({ Count: -1, FormId: "EuolExecution", Name: this.$t("Исполнение") });

            if (this.dataSource?.Data.Object.Pages)
                pages = pages.concat(this.dataSource?.Data.Object.Pages?.filter(page => page.FormId != '0202130') ?? []);

            if (this.dataSource?.Data.FormId === "0901021" && this.$store.getters['global/auth/getUserInfo'].Permissions.includes("CitizenStatementList"))
                pages.push({ Count: this.similarCount, FormId: "EuolSimilar", Name: this.$t("Похожие_обращения") });

            return pages;
        },
        similarFilter() {
            if (typeof this.document === 'undefined' || this.dataSource?.Data.FormId !== "0901021" || !this.$store.getters['global/auth/getUserInfo'].Permissions.includes("CitizenStatementList")) return null;

            return {
                RegState: 1,
                PerCategory: this.document.Card.PerCategory,
                SearchStr: this.getConcatedName(),
                IsGovService: "0",
                ExcludeById: this.document.id
            };
        }
    },
    data() {
        return {
            tab: null,
            similarCount: 0
        }
    },
    methods: {
        ...mapActions('global/actionsource', ['Edit', 'Save', 'SaveAndClose', 'AddFile']),
        async onToolbarClick(event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('global/actionsource/')).map(i => {return i.split('/')[2]})
            
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose') {
                    let validate_result = this.$refs.form.validate();
                    if (validate_result && this.dataSource.Data.Object.Document.Card.ExecuterName) {
                        await this.$store.dispatch(`global/actionsource/${button.Action}`, { event, button });        
                    }
                    else {
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else if (button.Action == 'RegisterEuolStatementRepeat' || button.Action == 'RegisterEuolStatementDuplicate') {
                    let similarIndex = Array.from(this.pages).findIndex(x => x.FormId === 'EuolSimilar');
                    let similatTabActive = this.tab === similarIndex;

                    if (!similatTabActive) {
                        this.$store.commit('dialogs/registerEuolStatementAs/SET_ORIGIN', null);
                        
                        this.$notify.confirm(
                            i18n.t('Не_выбрано_первичное_обращение._Перейти_на_вкладку_Похожие_обращения_для_выбора_первичного_обращения'),
                            async () =>
                            {
                                this.tab = similarIndex;
                            }
                        );
                    }
                    else if (this.$store.getters['dialogs/registerEuolStatementAs/getOrigin'] == null) {
                        this.$notify.alert(i18n.t('Не_выбрано_первичное_обращение.'));
                    }
                    else
                    {
                        await this.$store.dispatch(`global/actionsource/${button.Action}`, { event, button });
                    }
                }
                else
                {
                    await this.$store.dispatch(`global/actionsource/${button.Action}`, { event, button });
                }
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        onTabClicked(formId) {
            if (this.$refs[formId])
                this.$refs[formId][0]?.update();
        },
        getConcatedName() {

            if (this.document.Card.PerCategory === 2)
                return this.document.Card.PerEnterpriseName.trim();
            
            let result = "";

            if (this.document.Card.PerFirstName)
                result = this.document.Card.PerFirstName.trim();

            if (this.document.Card.PerSecondName)
                result += ` ${this.document.Card.PerSecondName.trim()}`;

            if (this.document.Card.PerMiddleName)
                result += ` ${this.document.Card.PerMiddleName.trim()}`;

            return result;
        }
    },
    async created() {
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs.form.validate();
        }  
    },
    watch: {
        async similarFilter(newVal) {
            if (newVal) {
                let response = await httpAPI({
                    url: `api/archive/list?collection=EuolStatements&filter=${JSON.stringify(newVal)}`,
                    method: 'GET'
                });

                if (response)
                    this.similarCount = response.data?.Payload?.total ?? 0;
            }
        }
    },
    mounted() {        
        this.$eventBus.$on('set-active-tab', async ({ FormId }) => {
            let page = this.pages.find(x => x.FormId === FormId);

            if (page) {
                let targetIndex = this.pages.indexOf(page);

                if (this.tab != targetIndex)
                    this.tab = targetIndex;
                else
                    this.$eventBus.$emit('update-active-tab');
            }
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('set-active-tab');
    }
}
</script>